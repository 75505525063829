
const breakpoints = {
  "2xl": "96em",
  base: "0em",
  lg: "62em",
  md: "48em",
  sm: "30em",
  xl: "80em",
}

export default breakpoints
