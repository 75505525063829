
import colors from "../colors"

const borders = {
  "1px": "0.0625rem solid",
  "2px": "0.125rem solid",
  "4px": "0.25rem solid",
  "8px": "0.5rem solid",
  "1": `0.0625rem solid ${colors.gray[200]}`,
  "2": `0.125rem solid ${colors.gray[200]}`,
  "3": `0.25rem solid ${colors.gray[200]}`,
  "4": `0.5rem solid ${colors.gray[200]}`,
  none: 0,
}

export default borders
