
const card = {
  display: "block",
  ":hover": {
    textDecoration: "none",
  },
  ":focus": {
    textDecoration: "none",
    boxShadow: "none",
  },
}

const raisedCard = {
  display: "block",
  boxShadow: "md",
  bg: "white",
  borderRadius: "md",
  ":hover": {
    textDecoration: "none",
    boxShadow: "lg",
  },
  ":focus": {
    textDecoration: "none",
    boxShadow: "lg_outline",
  },
}

const link = {
  variants: {
    breadcrumb: {
      fontSize: "sm",
      lineHeight: "shorter",
      fontWeight: 600,
    },
    cardLink: raisedCard,
    raisedCard,
    card,
    inText: {
      textDecoration: "underline",
      color: "brand.500",
      ":hover": {
        color: "brand.600",
      },
      ":focus": {
        color: "brand.600",
      },
      ":active": {
        color: "brand.600",
      },
    }
  },
}

export default link
