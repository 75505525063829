
const sizes = {
  container: {
    sm: "40rem",
    md: "60rem",
    lg: "70rem",
    xl: "90rem",
  },
}

export default sizes
