import { ChakraProvider } from "@chakra-ui/react"
import { Provider } from "next-auth/client"
import Script from 'next/script'

import "@reach/combobox/styles.css"

import theme from "@/theme/index"

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Script strategy="afterInteractive" src="https://www.googletagmanager.com/gtag/js?id=G-SNPD633N87"/>
      <Script
        id='google-analytics'
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-SNPD633N87', {
              page_path: window.location.pathname,
            });
          `,
          }}
      />
      <ChakraProvider theme={theme}>
        <Provider session={pageProps.session}>
          <Component {...pageProps} />
        </Provider>
      </ChakraProvider>
    </>
  )
}

export default MyApp
