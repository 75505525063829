
const lineHeights = {
  base: "calc(1em + 0.5rem)",
  none: 1,
  normal: "normal",
  short: "calc(1em + 0.375rem)",
  shorter: "calc(1em + 0.25rem)",
  shortest: "calc(1em + 0.125rem)",
  tall: "calc(1em + 0.625rem)",
  taller: "calc(1em + 1rem)",
}

export default lineHeights
