
const styles = {
  global: {
    body: {
      fontFamily: "body",
      lineHeight: "base",
      fontWeight: 500,
      color: "gray.900",
      bg: "gray.50",
    },
    "*::placeholder": {
      color: "gray.400",
    },
    "*, *::before, &::after": {
      borderColor: "gray.200",
      wordWrap: "break-word",
    },
    "[data-user-value]": { fontWeight: 700 },
    "[data-suggested-value]": { fontWeight: 400 },
  },
}

export default styles
