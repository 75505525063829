const switchTheme = {
  defaultProps: {
    size: "md",
  },
  baseStyle: ({ size, colorScheme}) => {
    return {
      track: {
        bg: "gray.300",
      },
      thumb: {
        boxShadow: "lg",
      },
      // container: {
      //   bg: "#CCC",
      // },
    }
  },
  sizes: {
    sm: {
      container: {
        "--slider-track-height": "1.25rem",
        "--slider-track-width": "2.125rem",
      },
    },
    md: {
      container: {
        "--slider-track-height": "1.5rem",
        "--slider-track-width": "2.75rem",
      },
    },
    lg: {
      container: {
        "--slider-track-height": "1.75rem",
        "--slider-track-width": "3rem",
      },
    }
  },
}

export default switchTheme