
const container = {
  baseStyle: {
    maxW: "container.lg",
    mx: "auto",
    px: ["1rem", "2.5rem"],
    w: "100%",
  },
}

export default container
