
import { theme } from "@chakra-ui/react"

const colors = {
  ...theme.colors,
  gray: {
    "50": "#F9FAFB",
    "100": "#F4F4F6",
    "200": "#E9EAEC",
    "300": "#D6D8DC",
    "400": "#C0C3C9",
    "500": "#979CA5",
    "600": "#79808B",
    "700": "#505662",
    "800": "#3C4049",
    "900": "#1B1D23",
  },
  blue: {
    "900": "#003C7A",
    "800": "#0057B2",
    "700": "#0070E5",
    "600": "#1F8CFF",
    "500": "#4DA4FF",
    "400": "#66B1FF",
    "300": "#9CC8F6",
    "200": "#C9E0F8",
    "100": "#E5F2FF",
    "50": "#FFFFFF",
  },
  teal: {
    "900": "#126881",
    "800": "#157693",
    "700": "#24A1C7",
    "600": "#3CB7DC",
    "500": "#68C7E4",
    "400": "#82CDE3",
    "300": "#9AD2E4",
    "200": "#C9E4EE",
    "100": "#ECF5F8",
    "50": "#FFFFFF",
  },
  purple: {
    "900": "#2424A8",
    "800": "#3939D0",
    "700": "#4E4EDA",
    "600": "#6C6CE5",
    "500": "#8282E3",
    "400": "#9F9FE5",
    "300": "#C6C6F0",
    "200": "#DFDFF6",
    "100": "#EFEFFB",
    "50": "#FFFFFF",
  },
  amber: {
    "900": "#B77706",
    "800": "#D08501",
    "700": "#EA9601",
    "600": "#F0A21A",
    "500": "#F2AF39",
    "400": "#F5BF61",
    "300": "#F8CF86",
    "200": "#F9DEAE",
    "100": "#FDF5E7",
    "50": "#FFFFFF",
  },
  coral: {
    "900": "#A71B11",
    "800": "#D4372B",
    "700": "#E54438",
    "600": "#F95448",
    "500": "#FA7268",
    "400": "#F9928B",
    "300": "#FBB3AC",
    "200": "#FACFCB",
    "100": "#FDEDEC",
    "50": "#FFFFFF",
  },
  orange: {
    "900": "#C63601",
    "800": "#E3460D",
    "700": "#EC5822",
    "600": "#FB7646",
    "500": "#FC8E66",
    "400": "#FBA789",
    "300": "#FCC1AB",
    "200": "#FDD3C4",
    "100": "#FEEDE7",
    "50": "#FFFFFF",
  },
  green: {
    "900": "#0A573E",
    "800": "#116A4D",
    "700": "#208D6A",
    "600": "#25A279",
    "500": "#31C192",
    "400": "#5FCEAA",
    "300": "#82D9BC",
    "200": "#BEEFDE",
    "100": "#EBFAF5",
    "50": "#FFFFFF",
  },

  brand: {
    "900": "#003C7A",
    "800": "#0057B2",
    "700": "#0070E5",
    "600": "#1F8CFF",
    "500": "#4DA4FF",
    "400": "#66B1FF",
    "300": "#9CC8F6",
    "200": "#C9E0F8",
    "100": "#E5F2FF",
    "50": "#FFFFFF",
  },
}

export default colors
