
const button = {
  baseStyle: {
    lineHeight: "1.25",
  },
  sizes: {
    xs: {
      fontSize: "md",
      h: "1.75rem",
      px: "xs",
      borderRadius: "sm",
    },
    sm: {
      fontSize: "md",
      h: "2rem",
      px: "1rem",
      borderRadius: "sm",
    },
    md: {
      fontSize: "md",
      h: "2.5rem",
      px: "1.5rem",
      borderRadius: "md",
    },
    lg: {
      fontSize: "md",
      h: "3rem",
      px: "1.75rem",
      borderRadius: "md",
    },
  },
  variants: {
    buttonGroupGhost: ({ theme, colorScheme }) => ({
      bg: "rgba(255,255,255,0)",
      cursor: "pointer",
      _checked: {
        bg: theme.colors[colorScheme][100],
      },
    }),
    buttonGroupSolid: ({ theme, colorScheme }) => ({
      bg: "gray.100",
      cursor: "pointer",
      _checked: {
        bg: theme.colors[colorScheme][500],
        color: "white",
      },
    }),
    buttonGroupOutline: ({ theme, colorScheme }) => ({
      bg: "rgba(255,255,255,0)",
      border: "2",
      borderColor: "gray.200",
      cursor: "pointer",
      _checked: {
        bg: theme.colors[colorScheme][100],
        borderColor: theme.colors[colorScheme][500],
        color: theme.colors[colorScheme][600],
      },
    }),
    buttonListOutline: ({ theme, colorScheme }) => ({
      bg: "rgba(255,255,255,0)",
      border: "2",
      borderColor: "gray.200",
      cursor: "pointer",
      overflow: "hidden",
    }),
    buttonListOutlineChecked: ({ theme, colorScheme }) => ({
      bg: theme.colors[colorScheme][100],
      border: "2",
      borderColor: theme.colors[colorScheme][500],
      color: theme.colors[colorScheme][600],
      cursor: "pointer",
    }),
    chartDownload: () => {
      return {
        borderRadius: "full",
        color: "white",
        bg: "green.500",
        minW: "3.5rem",
        w: "3.5rem",
        h: "3.5rem",
        px: 0,
        boxShadow: "lg",
        ":hover": { bg: "green.600" },
        ":focus": { bg: "green.600" },
        ":active": { bg: "green.700" },
      }
    },
    guideAction: ({ theme, colorScheme }) => {
      const isGray = colorScheme === "gray"
      const bgBase = theme.colors[colorScheme]
      return {
        borderRadius: "full",
        color: isGray ? "currentcolor" : "white",
        bg: bgBase[isGray ? 100 : 500],
        minW: "10rem",
        ":hover": {
          bg: bgBase[isGray ? 200 : 600],
        },
        ":focus": {
          bg: bgBase[isGray ? 200 : 600],
        },
        ":active": {
          bg: bgBase[isGray ? 300 : 700],
        },
      }
    },
    nav: ({ theme, colorScheme }) => {
      const cs = colorScheme || "brand"
      return {
        fontSize: "sm",
        lineHeight: "shorter",
        textTransform: "none",
        fontWeight: 600,
        borderRadius: "full",
        bg: theme.colors[cs][100],
        color: theme.colors[cs][700],
        ":hover": {
          bg: theme.colors[cs][200],
        },
        ":focus": {
          bg: theme.colors[cs][200],
        },
        ":active": {
          bg: theme.colors[cs][200],
        },
      }
    },
    ghost: {
      fontWeight: 600,
      borderRadius: "full",
      textTransform: "none",
      ":hover": {
        bg: "gray.100",
      },
      ":focus": {
        bg: "gray.200",
      },
      ":active": {
        bg: "gray.200",
      },
    },
    navGhost: {
      fontSize: "sm",
      lineHeight: "shorter",
      fontWeight: 600,
      borderRadius: "full",
      textTransform: "none",
      bg: "white",
      ":hover": {
        bg: "gray.100",
      },
      ":focus": {
        bg: "gray.200",
      },
      ":active": {
        bg: "gray.200",
      },
    },
    menuLink: {
      justifyContent: "flex-start",
      borderRadius: "0",
      ":hover": { bg: "gray.50" },
      ":focus": { bg: "gray.50" },
      ":active": { bg: "gray.100" },
    },
  },
}

export default button
