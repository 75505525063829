const list = {
  baseStyle: {
    // item: {
    //   color: "#00ADEE",
    // },
  },
  variants: {
    body: {
      item: {
        fontSize: "lg",
        lineHeight: "tall",
        ml: "1.25rem",
      },
    },
  }
}

  export default list