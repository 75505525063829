
const tabs = {
  defaultProps: {
    colorScheme: "brand",
  },
  baseStyle: {
    tablist: {
      h: "3rem",
    },
    tab: {
      fontWeight: 600,
    },
  },
}

export default tabs
