
const shadows = {
  "outline": "0 0 0 0.1875rem rgba(77, 164, 255, 0.6)",
  "base": "0 0.0625rem 0.1875rem 0 rgba(0, 0, 0, 0.1), 0 0.625rem 0.125rem 0 rgba(0, 0, 0, 0.06)",
  "xs": "0 0 0 0.0625rem rgba(0, 0, 0, 0.05)",
  "sm": "0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.05)",
  "md": "0 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1), 0 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06)",
  "lg": "0 0.5rem 1rem -0.1875rem rgba(0, 0, 0, 0.1), 0 0.25rem 0.375rem -0.125rem rgba(0, 0, 0, 0.05)",
  "xl": "0 1.25rem 1.5rem -0.375rem rgba(0, 0, 0, 0.1), 0 0.5rem 0.5rem -0.375rem rgba(0, 0, 0, 0.04)",
  "2xl": "0 1.5rem 3rem -0.75rem rgba(0, 0, 0, 0.25)",
}

const shadowsCombined = {
  "dark-lg": "rgba(0, 0, 0, 0.1) 0px 0px 0px 0.0625rem, rgba(0, 0, 0, 0.2) 0px 0.25rem 0.5rem, rgba(0, 0, 0, 0.4) 0px 1rem 2.5rem",
  "inner": "inset 0 0.125rem 0.25rem 0 rgba(0,0,0,0.06)",    
  "outline": shadows.outline,
  "none": "none",
  base: shadows.base,
  xs: shadows.xs,
  sm: shadows.sm,
  md: shadows.md,
  lg: shadows.lg,
  xl: shadows.xl,
  "2xl": shadows["2xl"],
  "base_outline": `${shadows.base}, ${shadows.outline}`,
  "xs_outline": `${shadows.xs}, ${shadows.outline}`,
  "sm_outline": `${shadows.sm}, ${shadows.outline}`,
  "md_outline": `${shadows.md}, ${shadows.outline}`,
  "lg_outline": `${shadows.lg}, ${shadows.outline}`,
  "xl_outline": `${shadows.xl}, ${shadows.outline}`,
  "2xl_outline": `${shadows["2xl"]}, ${shadows.outline}`,
}

export default shadowsCombined
