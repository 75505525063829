const selectTheme = {
  baseStyle: {},
  variants: {
    guideSelect: {
      field: {
        fontWeight: 600,
        minW: "8rem",
        bg: "gray.100",
        cursor: "pointer",
        "&:hover": {
          bg: "gray.200",
        },
        "&:focus": {
          boxShadow: "outline",
        },
      },
    },
  },
  sizes: {
    lg: { field: { h: "4rem", px: "lg", fontSize: "md" } },
    md: { field: { h: "3rem", px: "md", fontSize: "md" } },
    sm: { field: { h: "2rem", px: "sm", fontSize: "md" } },
  },
}

export default selectTheme
