
const fontSizes = {
  xs: "0.75rem",
  sm: "0.875rem",
  md: "1rem",
  lg: "1.25rem",
  xl: "1.5rem",
  "2xl": "1.75rem",
  "3xl": "2.125rem",
  "4xl": "2.75rem",
  "5xl": "3.25rem",
}

export default fontSizes
