
const heading = {
  baseStyle: {
    lineHeight: "shorter",
  },
  variants: {
    heroTitle: {
      fontSize: ["3xl", null, "4xl", null, "5xl"],
    },
    heroSubtitle: {
      fontSize: ["lg", null, "xl"],
      color: "gray.600",
      lineHeight: "tall",
    },
    subTitle: {
      fontSize: ["2xl", null, "3xl", null, "4xl"],
    },
    footerTitle: {
      fontSize: ["2xl", null, "3xl", null, "2.5rem"],
    },
    stepTitle: {
      fontSize: ["2xl", null, null, null, "3xl"],
    },
    guideTitle: {
      fontSize: "3xl",
      lineHeight: "short",
      textAlign: "center",
    },
    pageTitle: {
      fontSize: "2xl",
    },
    sectionTitle: {
      fontSize: "xl",
    },
    datasetTitle: {
      fontSize: "lg",
    },
    chartTitle: {
      fontSize: ["md", null, "lg"],
    },
    modalTitle: {
      fontSize: "lg",
    },
    heroInfo: {
      fontSize: "1rem",
      lineHeight: "base",
      fontWeight: "500"
    },
  },
  defaultProps: { size: null },
}

export default heading
