
const text = {
  baseStyle: {
    fontSize: "1rem",
    lineHeight: "base",
  },
  variants: {
    guideTitle: {
      fontSize: "3xl",
      lineHeight: "short",
      fontWeight: 700,
      textAlign: "center",
    },
    guideText: {
      fontSize: "lg",
      lineHeight: "base",
      fontWeight: 500,
      color: "gray.700",
      textAlign: "center",
      maxW: "45rem",
    },
    breadcrumb: {
      fontSize: "sm",
      lineHeight: "shorter",
      fontWeight: 600,
    },
    datasetSubtitle: {
      fontSize: "sm",
      lineHeight: "short",
      color: "gray.600",
      fontWeight: 500,
    },
    chartSubtitle: {
      fontSize: "sm",
      lineHeight: "shorter",
      color: "gray.600",
      fontWeight: 600,
    },
    heroSubtitle: {
      fontSize: ["lg", null, "xl"],
      color: "gray.600",
      lineHeight: "tall",
    },
    pageSubtitle: {
      fontSize: "md",
      color: "gray.600",
    },
    accordionTitle: {
      fontSize: ["lg", null, "xl"],
      color: "#2B2B35",
      fontWeight: "bold",
      py: "0.4rem"
    },
    accordionBody: {
      fontSize: ["md", null, "lg"],
      color: "#2B2B35",
    },
    body: {
      fontSize: "lg",
      lineHeight: "tall",
    },
    modalBodyText: {
      fontSize: "md",
      color: "gray.600",
    },
    footerText: {
      fontSize: "sm",
      color: "gray.600",
    },
  },
}

export default text
