import { extendTheme } from "@chakra-ui/react"

import colors from "./colors"
import {
  buttonTheme,
  containerTheme,
  headingTheme,
  linkTheme,
  tabsTheme,
  textTheme,
  switchTheme,
  selectTheme,
  listTheme,
} from "./components"

import styles from "./styles"

import breakpoints from "./foundations/breakpoints"
import sizes from "./foundations/sizes"
import radii from "./foundations/radii"
import borders from "./foundations/borders"
import space from "./foundations/space"
import fontSizes from "./foundations/fontSizes"
import lineHeights from "./foundations/lineHeights"
import shadows from "./foundations/shadows"
import fonts from "./foundations/fonts"

const vizdiumTheme = extendTheme({
  config: {
    cssVarPrefix: "vz",
    initialColorMode: "light",
    useSystemColorMode: false,
  },

  direction: "ltr",

  styles,

  breakpoints,

  colors,
  sizes,
  radii,
  borders,
  space,
  shadows,

  fonts,
  fontSizes,
  lineHeights,

  /**
   *
   * Components
   *
   */

  components: {
    Container: containerTheme,
    Text: textTheme,
    Heading: headingTheme,
    List: listTheme,
    Tabs: tabsTheme,
    Button: buttonTheme,
    Link: linkTheme,
    Switch: switchTheme,
    Select: selectTheme,
    Menu: {
      baseStyle: {
        list: { border: 0, boxShadow: "lg", borderRadius: "sm" },
      },
    },
  },
})

export default vizdiumTheme
